import { ReactNode, useState } from "react"
import { Tooltip } from "react-tooltip"
import Icons from "lib/components/Icons"

export interface TooltipsProps {
  description: ReactNode
  name?: string
  place?: "top" | "right" | "bottom" | "left"
  iconName?: string
}

function TooltipsComponent ({name="tooltip", place="right", iconName= "info", description}: TooltipsProps) {
  const [isTooltipVisible, setTooltipVisibility] = useState(false)

  return (
    <div className="mb-1">
      <button
        type="button"
        data-tooltip-id={name}
        onMouseEnter={() => setTooltipVisibility(true)}
        onMouseLeave={() => setTooltipVisibility(false)}
        onClick={() => setTooltipVisibility(false)}
      >
        <Icons iconName={iconName} color="gray" />
      </button>
      <Tooltip
        id={name}
        isOpen={isTooltipVisible}
        place={place}
        className="z-10"
      >
        {description}
      </Tooltip>
    </div>
  )
}

export default TooltipsComponent
