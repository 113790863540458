import Flex from "@components/Container/Flex"
import Skeleton from ".."

function SkeletonHomeSlider() {
  return (
    <section className="relative mt-8 lg:mt-24">
      <div className="container mx-auto w-full px-4 sm:p-0 sm:w-[959px]">
        <div className="hidden sm:block">
          <Skeleton classSize="h-40 w-full mb-4" rounded="lg" bgColor="primary-light" />
        </div>
        <div className="block sm:hidden relative">
          <Skeleton classSize="h-[152px] w-full mb-4" rounded="lg" bgColor="primary-light" />
        </div>
          <div className="px-4 mt-2 sm:mt-0 flex gap-2 place-content-center">
            <Skeleton classSize="h-2 w-2" rounded="lg" bgColor="primary-light" />
            <Skeleton classSize="h-2 w-2" rounded="lg" bgColor="primary-light" />
            <Skeleton classSize="h-2 w-2" rounded="lg" bgColor="primary-light" />
          </div>
      </div>
    </section>
  )
}

export default SkeletonHomeSlider
