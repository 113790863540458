import SkeletonHomeSlider from "@components/Skeleton/SkeletonHomeSlider"
import fetcher from "lib/fetcher"
import Icon from "lib/components/Icons"
import dynamic from "next/dynamic"
import { createRef, useState } from "react"
import { isDesktop } from "react-device-detect"
import Slider from "react-slick"
import useSWR from "swr"

const ImageComponent = dynamic(() => import("lib/components/Image"), {
  ssr: false,
})

function SampleNextArrow(props) {
  const { onClick } = props

  return (
    <div
      className="absolute top-[40%] -right-[50px] block w-[40px] h-[40px] rounded-full flex items-center justify-center cursor-pointer bg-[#F8F8F8] hover:bg-black"
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <Icon 
        iconName="arrow-right"
        width={40}
        height={40}
        styles="text-[#1F2937] hover:text-white"
        />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <div
      className="absolute top-[40%] -left-[50px] block w-[40px] h-[40px] rounded-full flex items-center justify-center cursor-pointer bg-[#F8F8F8] hover:bg-black"
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <Icon 
        iconName="arrowLeft"
        width={40}
        height={40}
        styles="text-[#1F2937] hover:text-white"
        />
    </div>
  )
}

function SliderHomepage() {
  const { data: dataSlider } = useSWR("/api/slider", fetcher)
  const [currentSlideIndex, setCurrentSlide] = useState<number>(0)
  const customSlider: {
    current
  } = createRef()

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (_prev: number, next: number) => {
      setCurrentSlide(next)
    },
    arrows: isDesktop,
  }

  return (
    <section className="relative mt-8 lg:mt-16">
      <div className="container mx-auto w-full px-4 sm:p-0 sm:w-[959px]">
        <Slider {...settings} ref={customSlider}>
          {dataSlider?.slider.map((sliderItem, sliderIndex) =>
            <a key={sliderItem.image_url} className="relative" href={sliderItem.link_url} aria-label={`Slider ${sliderIndex}`}>
              <div className="hidden sm:block">
                <ImageComponent
                  src={sliderItem.image_url}
                  width={959}
                  height={205}
                  alt={`Slider ${sliderIndex}`}
                  rounded
                  roundedSize="extra-large"
                  />
              </div>
              <div className="flex sm:hidden relative w-full items-center justify-center">
                <ImageComponent
                  src={sliderItem.image_mobile_url}
                  objectFit="contain"
                  alt={`Slider ${sliderIndex}`}
                  width={300}
                  height={150}
                  rounded
                  roundedSize="extra-large"
                  />
              </div>
            </a>,
          )}
        </Slider>
        {dataSlider?.slider.length > 1 &&
          <div className="px-4 mt-2 sm:mt-4 flex gap-2 place-content-center">
            {dataSlider?.slider.map((_, indexSlider) => {
              let bgGray = "bg-[#D1D5DB]"
              if (indexSlider === currentSlideIndex) {
                bgGray = "bg-primary"
              }
              return <div 
                tabIndex={0} 
                aria-label="Slider"
                role="button"  
                onClick={() => customSlider.current.slickGoTo(indexSlider)} 
                key={indexSlider} 
                className={`${indexSlider === currentSlideIndex ? "w-6" : "w-2"} h-2 ${bgGray} rounded-full cursor-pointer`} 
                />
            })}
          </div>
        }
      </div>
    </section>
  )
}

export default SliderHomepage
