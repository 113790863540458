import { useContext, useEffect, useState } from "react"
import { motion } from "framer-motion"
import dynamic from "next/dynamic"
import { AttentionSeeker } from "react-awesome-reveal"

import { cutString } from "lib/helpers"
import Button from "lib/components/Button"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import consola from "consola"
import { gtmEvent } from "lib/gtm"

const ImageComponent = dynamic(() => import("lib/components/Image"), {
  ssr: false,
})

function ProgramServices() {
  const { useTranslation: t } = useContext(LanguageContext)

  const [showMoreInhouse, setShowMoreInhouse] = useState(false)
  const [showMorePublic, setShowMorePublic] = useState(false)
  const [showMoreTms, setShowMoreTms] = useState(false)
  const [showMoreTos, setShowMoreTos] = useState(false)
  const [activeServices, setActiveServices] = useState(null)

  useEffect(() => {
    function handleNewMessage () {
      try {
        const newMessageQiscus = localStorage.getItem("activeServices")
        if (newMessageQiscus) {
          const messages = localStorage.getItem("activeServices")
          setActiveServices(messages)
        } else {
          setActiveServices(null)
        }
      } catch(e) {
        consola.log(e.message)
      }
    }

    window.addEventListener("storage", handleNewMessage)
    return () => {
      window.removeEventListener("storage", handleNewMessage)
      localStorage.removeItem("activeServices")
      setActiveServices(null)
    }
  }, [])

  function openChat() {
    const element: HTMLElement = document.getElementsByClassName(
      "qcw-trigger-btn",
    )[0] as HTMLElement

    element.click()
  }

  return (
    <section className="relative mt-8 mb-0 sm:mt-24 sm:mb-16">
      <div className="container mx-auto flex flex-col items-center relative  px-4 lg:px-0">
        <Text weight="semibold" color="gray-darker" customClass="leading-8 text-xl sm:text-3xl">
          {t("our_programs_services")}
        </Text>
        {/* DESKTOP */}
        <div className="hidden sm:grid grid-cols-2 gap-5 my-8 w-full">
          <div className="flex flex-col gap-5">
            <motion.div
              className="border border-gray-300 p-4 rounded-xl" id="programs-inhouse">
              <Text size="large" weight="semibold" customClass="z-10">
                {t("programs_inhouse")}
              </Text>
              <div className="relative h-[264px] my-3">
                <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701781841/homepage/inhouse.webp" alt="inhouse-programs"/>
              </div>
              <div>
                <Text size="small">
                  {t("inhouse_programs_service_desc")}
                </Text>
              </div>
              <hr className="my-4"/>
              <div className="flex flex-col gap-y-1">
                <div className="flex gap-x-2">
                  <div className="w-[18px]">
                    <div className="h-[18px] mt-0.5 w-[18px]">
                      <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                    </div>
                  </div>
                  <Text size="small">
                    {t("inhouse_programs_service_ops_one")}
                  </Text>
                </div>
                <div className="flex gap-x-2">
                  <div className="w-[18px]">
                    <div className="h-[18px] mt-0.5 w-[18px]">
                      <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                    </div>
                  </div>
                  <Text size="small">
                    {t("inhouse_programs_service_ops_two")}
                  </Text>
                </div>
                <div className="flex gap-x-2">
                  <div className="w-[18px]">
                    <div className="h-[18px] mt-0.5 w-[18px]">
                      <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                    </div>
                  </div>
                  <Text size="small">
                    {t("inhouse_programs_service_ops_three")}
                  </Text>
                </div>
                <div className="flex gap-x-2">
                  <div className="w-[18px]">
                    <div className="h-[18px] mt-0.5 w-[18px]">
                      <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                    </div>
                  </div>
                  <Text size="small">
                    {t("inhouse_programs_service_ops_four")}
                  </Text>
                </div>
              </div>
              <div role="button" tabIndex={0} className="mt-8 mb-2" onClick={() => {
                gtmEvent("ce_section_engagement", {
                  section_name: t("programs_inhouse"),
                  button_name: t("free_consultation"),
                })
              }}>
                <Button variant="contained" color="primary" href="/business#form_leads_section" customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                  {t("free_consultation")}
                </Button>
              </div>
            </motion.div>
            {activeServices === "training-organizer-services" ?
              <AttentionSeeker effect="headShake">
                <div
                  onMouseOver={() => {
                    localStorage.removeItem("activeServices")
                    const storageEvent = new Event("storage")
                    window.dispatchEvent(storageEvent)
                  }}
                  onFocus={() => {
                    localStorage.removeItem("activeServices")
                    const storageEvent = new Event("storage")
                    window.dispatchEvent(storageEvent)
                  }}
                  className="border border-gray-300 p-4 rounded-xl" id="training-organizer-services">
                  <Text size="large" weight="semibold" customClass="z-10">
                    {t("training_organizer_services")}
                  </Text>
                  <div className="relative h-[264px] my-3">
                    <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701852864/homepage/training_organizer.webp" alt="training-organizer"/>
                  </div>
                  <div>
                    <Text size="small">
                      {t("training_organizer_service_desc")}
                    </Text>
                  </div>
                  <hr className="my-4"/>
                  <div className="flex flex-col gap-y-1">
                    <div className="flex gap-x-2">
                      <div className="w-[18px]">
                        <div className="h-[18px] mt-0.5 w-[18px]">
                          <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                        </div>
                      </div>
                      <Text size="small">
                        {t("training_organizer_service_ops_one")}
                      </Text>
                    </div>
                    <div className="flex gap-x-2">
                      <div className="w-[18px]">
                        <div className="h-[18px] mt-0.5 w-[18px]">
                          <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                        </div>
                      </div>
                      <Text size="small">
                        {t("training_organizer_service_ops_two")}
                      </Text>
                    </div>
                    <div className="flex gap-x-2">
                      <div className="w-[18px]">
                        <div className="h-[18px] mt-0.5 w-[18px]">
                          <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                        </div>
                      </div>
                      <Text size="small">
                        {t("training_organizer_service_ops_three")}
                      </Text>
                    </div>
                  </div>
                  <div role="button" tabIndex={0} className="mt-8 mb-2" onClick={() => {
                      gtmEvent("ce_section_engagement", {
                        section_name: t("training_organizer_services"),
                        button_name: t("contact_us"),
                      })
                    }}>
                    <Button variant="contained" color="primary" onClick={openChat} customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                      {t("contact_us")}
                    </Button>
                  </div>
                </div>
              </AttentionSeeker>
              :
              <div
                className="border border-gray-300 p-4 rounded-xl" id="training-organizer-services">
                <Text size="large" weight="semibold" customClass="z-10">
                  {t("training_organizer_services")}
                </Text>
                <div className="relative h-[264px] my-3">
                  <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701852864/homepage/training_organizer.webp" alt="training-organizer"/>
                </div>
                <div>
                  <Text size="small">
                    {t("training_organizer_service_desc")}
                  </Text>
                </div>
                <hr className="my-4"/>
                <div className="flex flex-col gap-y-1">
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_organizer_service_ops_one")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_organizer_service_ops_two")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_organizer_service_ops_three")}
                    </Text>
                  </div>
                </div>
                <div role="button" tabIndex={0} className="mt-8 mb-2" onClick={() => {
                    gtmEvent("ce_section_engagement", {
                      section_name: t("training_organizer_service"),
                      button_name: t("contact_us"),
                    })
                  }}>
                  <Button variant="contained" color="primary" onClick={openChat} customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                    {t("contact_us")}
                  </Button>
                </div>
              </div>
            }
          </div>
          <div className="flex flex-col gap-5">
            <motion.div
              className="border border-gray-300 p-4 rounded-xl" id="programs-public">
              <Text size="large" weight="semibold" customClass="z-10">
                {t("programs_public")}
              </Text>
              <div className="relative h-[264px] my-3">
                <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1702450585/homepage/public.webp" alt="public-programs"/>
              </div>
              <div>
                <Text size="small" weight="bold">
                  {t("public_class")}
                </Text>
                <Text size="small">
                  {t("public_class_programs_service_desc")}
                </Text>
              </div>
              <div className="mt-4">
                <Text size="small" weight="bold">
                  Skill Booster
                </Text>
                <Text size="small">
                  {t("public_skill_booster_service_desc")}
                </Text>
              </div>
              <hr className="my-4"/>
              <div className="flex flex-col gap-y-1">
                <div className="flex gap-x-2">
                  <div className="w-[18px]">
                    <div className="h-[18px] mt-0.5 w-[18px]">
                      <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                    </div>
                  </div>
                  <Text size="small">
                    {t("public_programs_service_ops_one")}
                  </Text>
                </div>
                <div className="flex gap-x-2">
                  <div className="w-[18px]">
                    <div className="h-[18px] mt-0.5 w-[18px]">
                      <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                    </div>
                  </div>
                  <Text size="small">
                    {t("public_programs_service_ops_two")}
                  </Text>
                </div>
                <div className="flex gap-x-2">
                  <div className="w-[18px]">
                    <div className="h-[18px] mt-0.5 w-[18px]">
                      <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                    </div>
                  </div>
                  <Text size="small">
                    {t("public_programs_service_ops_three")}
                  </Text>
                </div>
                <div className="flex gap-x-2">
                  <div className="w-[18px]">
                    <div className="h-[18px] mt-0.5 w-[18px]">
                      <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                    </div>
                  </div>
                  <Text size="small">
                    {t("public_programs_service_ops_four")}
                  </Text>
                </div>
              </div>
              <div role="button" tabIndex={0} className="mt-8 mb-2" onClick={() => {
                gtmEvent("ce_section_engagement", {
                  section_name: t("programs_public"),
                  button_name: t("explore_program"),
                })
              }}>
                <Button variant="contained" color="primary" href="/programs/all?type=public" customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                  {t("explore_program")}
                </Button>
              </div>
            </motion.div>
            {activeServices === "training-management-services" ?
            <AttentionSeeker effect="headShake">
              <div
                onMouseOver={() => {
                  localStorage.removeItem("activeServices")
                  const storageEvent = new Event("storage")
                  window.dispatchEvent(storageEvent)
                }}
                onFocus={() => {
                  localStorage.removeItem("activeServices")
                  const storageEvent = new Event("storage")
                  window.dispatchEvent(storageEvent)
                }}
                className="border border-gray-300 p-4 rounded-xl" id="training-management-services">
                <Text size="large" weight="semibold" customClass="z-10">
                  {t("training_management_services")}
                </Text>
                <div className="relative h-[264px] my-3">
                  <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701781841/homepage/tms.webp" alt="training-organizer"/>
                </div>
                <div>
                  <Text size="small">
                    {t("training_management_service_desc")}
                  </Text>
                </div>
                <hr className="my-4"/>
                <div className="flex flex-col gap-y-1">
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_one")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_two")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_three")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_four")}
                    </Text>
                  </div>
                </div>
                <div role="button" tabIndex={0} className="mt-8 mb-2" onClick={() => {
                    gtmEvent("ce_section_engagement", {
                      section_name: t("training_management_service"),
                      button_name: t("contact_us"),
                    })
                  }}>
                  <Button variant="contained" color="primary" onClick={openChat} customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                    {t("contact_us")}
                  </Button>
                </div>
              </div>
            </AttentionSeeker>
            :
            <div
                className="border border-gray-300 p-4 rounded-xl" id="training-management-services">
                <Text size="large" weight="semibold" customClass="z-10">
                  {t("training_management_services")}
                </Text>
                <div className="relative h-[264px] my-3">
                  <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701781841/homepage/tms.webp" alt="training-organizer"/>
                </div>
                <div>
                  <Text size="small">
                    {t("training_management_service_desc")}
                  </Text>
                </div>
                <hr className="my-4"/>
                <div className="flex flex-col gap-y-1">
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_one")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_two")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_three")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_four")}
                    </Text>
                  </div>
                </div>
                <div role="button" tabIndex={0} className="mt-8 mb-2" onClick={() => {
                    gtmEvent("ce_section_engagement", {
                      section_name: t("training_management_service"),
                      button_name: t("contact_us"),
                    })
                  }}>
                  <Button variant="contained" color="primary" onClick={openChat} customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                    {t("contact_us")}
                  </Button>
                </div>
              </div>
            }
          </div>
        </div>

        {/* MOBILE */}
        <div className="flex sm:hidden flex-col gap-4 my-4 w-full">
          <div className="border border-gray-300 p-3 rounded-xl" id="programs-inhouse">
            <Text size="large" weight="semibold" customClass="z-10">
              {t("programs_inhouse")}
            </Text>
            <div className="relative aspect-[5/2] my-3">
              <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701781841/homepage/inhouse.webp" alt="inhouse-programs"/>
            </div>
            <div>
              <Text size="small">
                {showMoreInhouse ? t("inhouse_programs_service_desc") : cutString(t("inhouse_programs_service_desc"))}
              </Text>
            </div>
            {showMoreInhouse ?
              <>
                <hr className="my-4"/>
                <div className="flex flex-col gap-y-1">
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("inhouse_programs_service_ops_one")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("inhouse_programs_service_ops_two")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("inhouse_programs_service_ops_three")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("inhouse_programs_service_ops_four")}
                    </Text>
                  </div>
                </div>
              </>
              :
              <Button 
                variant="text"
                color="primary"
                onClick={() => setShowMoreInhouse(true)}
                >
                {t("show_more_complete")}
              </Button>
            }
            <div tabIndex={0} role="button" className="mt-4" onClick={() => {
                gtmEvent("ce_section_engagement", {
                  section_name: t("programs_inhouse"),
                  button_name: t("free_consultation"),
                })
              }}>
              <Button variant="contained" color="primary" href="/business#form_leads_section" customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                {t("free_consultation")}
              </Button>
            </div>
          </div>
          <div className="border border-gray-300 p-3 rounded-xl" id="programs-public">
            <Text size="large" weight="semibold" customClass="z-10">
              {t("programs_public")}
            </Text>
            <div className="relative aspect-[5/2] my-3">
              <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1702450585/homepage/public.webp" alt="public-programs"/>
            </div>
            <div>
              <Text size="small" weight="bold">
                {t("public_class")}
              </Text>
              <Text size="small">
                {showMorePublic ? t("public_class_programs_service_desc") : cutString(t("public_class_programs_service_desc"))}
              </Text>
            </div>
            {showMorePublic ? 
              <>
                <div className="mt-4">
                  <Text size="small" weight="bold">
                    Skill Booster
                  </Text>
                  <Text size="small">
                    {t("public_skill_booster_service_desc")}
                  </Text>
                </div>
                <hr className="my-4"/>
                <div className="flex flex-col gap-y-1">
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("public_programs_service_ops_one")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("public_programs_service_ops_two")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("public_programs_service_ops_three")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("public_programs_service_ops_four")}
                    </Text>
                  </div>
                </div>
              </>
              :
              <Button 
                variant="text"
                color="primary"
                onClick={() => setShowMorePublic(true)}
                >
                {t("show_more_complete")}
              </Button>
            }
            <div role="button" tabIndex={0} className="mt-4" onClick={() => {
                gtmEvent("ce_section_engagement", {
                  section_name: t("programs_public"),
                  button_name: t("explore_program"),
                })
              }}>
              <Button variant="contained" color="primary" href="/programs/all?type=public" customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                {t("explore_program")}
              </Button>
            </div>
          </div>
          <div className="border border-gray-300 p-3 rounded-xl" id="training-organizer-services">
            <Text size="large" weight="semibold" customClass="z-10">
              {t("training_organizer_services")}
            </Text>
            <div className="relative aspect-[5/2] my-3">
              <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701852864/homepage/training_organizer.webp" alt="training-organizer"/>
            </div>
            <div>
              <Text size="small">
                {showMoreTos ? t("training_organizer_service_desc") : cutString(t("training_organizer_service_desc"))}
              </Text>
            </div>
            {showMoreTos ? 
              <>
                <hr className="my-4"/>
                <div className="flex flex-col gap-y-1">
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_organizer_service_ops_one")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_organizer_service_ops_two")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_organizer_service_ops_three")}
                    </Text>
                  </div>
                </div>
              </>
              :
              <Button 
                variant="text"
                color="primary"
                onClick={() => setShowMoreTos(true)}
                >
                {t("show_more_complete")}
              </Button>
            }
            <div role="button" tabIndex={0} className="mt-4" onClick={() => {
                gtmEvent("ce_section_engagement", {
                  section_name: t("training_organizer_service"),
                  button_name: t("contact_us"),
                })
              }}>
              <Button variant="contained" color="primary" onClick={openChat} customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                {t("contact_us")}
              </Button>
            </div>
          </div>
          <div className="border border-gray-300 p-3 rounded-xl" id="training-management-services">
            <Text size="large" weight="semibold" customClass="z-10">
              {t("training_management_services")}
            </Text>
            <div className="relative aspect-[5/2] my-3">
              <ImageComponent rounded roundedSize="medium" objectFit="cover" src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701781841/homepage/tms.webp" alt="training-organizer"/>
            </div>
            <div>
              <Text size="small">
                {showMoreTms ? t("training_management_service_desc") : cutString(t("training_management_service_desc"))}
              </Text>
            </div>
            {showMoreTms ?
              <>
                <hr className="my-4"/>
                <div className="flex flex-col gap-y-1">
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_one")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_two")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_three")}
                    </Text>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="w-[18px]">
                      <div className="h-[18px] mt-0.5 w-[18px]">
                        <ImageComponent width={20} height={20} src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1701782525/homepage/verified.svg" alt="verified"/>
                      </div>
                    </div>
                    <Text size="small">
                      {t("training_management_service_ops_four")}
                    </Text>
                  </div>
                </div>
              </>
              :
              <Button 
                variant="text"
                color="primary"
                onClick={() => setShowMoreTms(true)}
                >
                {t("show_more_complete")}
              </Button>
            }
            <div tabIndex={0} role="button" className="mt-4" onClick={() => {
                gtmEvent("ce_section_engagement", {
                  section_name: t("training_management_service"),
                  button_name: t("contact_us"),
                })
              }}>
              <Button variant="contained" color="primary" onClick={openChat} customClass="w-40 h-10 text-sm sm:w-56 sm:h-12 sm:text-base">
                {t("contact_us")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProgramServices
