import { useEffect, useState } from "react"
import useSWR from "swr"

import Banner from "@components/Modal/Banner"
import { GetBanner, GET_POPUP_BANNER } from "@graphql/banner/getBanner"
import { initializeApollo } from "lib/apollo"
import fetcher from "lib/fetcher"
import consola from "consola"
import dynamic from "next/dynamic"
import SkeletonHomeSearch from "@components/Skeleton/SkeletonHomeSearch"
import SliderHomepage from "@components/Sections/Homepage/Slider"
import ProgramServices from "@components/Sections/Homepage/ProgramServices"
import SkeletonHomeSlider from "@components/Skeleton/SkeletonHomeSlider"
import HomeSearch from "@components/Sections/Homepage/HomeSearch"
import { NextSeo } from "next-seo"
import { useRouter } from "next/router"

const InterestJoin = dynamic(() => import("@components/Sections/AboutUs/InterestJoin"), {
  ssr: false,
})

const NewTrustedBy = dynamic(() => import("@components/Sections/NewTrustedBy"), {
  ssr: false,
})

const WhatTheySay = dynamic(() => import("@components/Sections/WhatTheySay"), {
  ssr: false,
})

const apolloClient = initializeApollo()

export async function getServerSideProps(ctx) {
  try {
    let activeLocale = ctx.locale
    const language = ctx.query.lang

    if (language) {
      activeLocale = language
    }

    const dataLanguage = await import(
      `../public/locales/${activeLocale.toUpperCase()}/translations.json`
    )

    const descriptionName = dataLanguage.description_seo_homepage

    const { data } = await apolloClient.query<GetBanner>({
      query: GET_POPUP_BANNER,
    })

    return {
      props: {
        banner: data.content_popup_banner,
        descriptionName,
      },
    }
  } catch (error) {
    return {
      props: {
        error,
      },
    }
  }
}



function Home({ banner, descriptionName }) {
  const router = useRouter()
  const [modalState, setModalState] = useState<boolean>(false)
  const [isModalCloseForever] = useState<boolean>(false)
  const [widthScreen, setWidthScreen] = useState<number>()
  const date = new Date().toLocaleDateString()

  const { data: dataTestimony } = useSWR("/api/testimony", fetcher)
  const { data: dataCompanies, isLoading } = useSWR("/api/client/list", fetcher)
  const { data: dataInfographics } = useSWR("/api/infographics?name=homepage", fetcher)
  const totalCompanies = dataInfographics?.infographics?.find(it => it.title === "Corporate Clients")

  const closeBanner = () => {
    if (isModalCloseForever) {
      localStorage.setItem("modal_status", "close_forever")
    } else {
      localStorage.setItem("modal_status", date)
    }
    setModalState(false)
  }
  const handleWindowSizeChange = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    setWidthScreen(window.innerWidth)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [widthScreen])

  useEffect(() => {
    try {
      const modalStatus = localStorage.getItem("modal_status") || "close"
      switch (!modalState) {
        case modalStatus === "close":
          return setModalState(true)
        case !modalStatus:
          return setModalState(true)
        case modalStatus === "close_forever":
          return setModalState(false)
        case modalStatus !== date:
          return setModalState(true)
        default:
          return setModalState(false)
      }
    } catch (error) {
      return consola.log(error.message)
    }    
  }, [modalState, date])

  return (
    <div className="overflow-hidden">
      <NextSeo title="PasarTrainer" description={descriptionName} canonical={`${process.env.NEXT_PUBLIC_URL}${router.pathname}`} />
      {!dataCompanies ? <SkeletonHomeSearch /> : <HomeSearch widthScreen={widthScreen} />}
      {!dataCompanies ? <SkeletonHomeSlider /> : <SliderHomepage />}
      <ProgramServices />
      <WhatTheySay dataTestimony={dataTestimony} slidesToScroll={2} />
      <NewTrustedBy totalCompanies={totalCompanies?.description} companies={dataCompanies} />
      <InterestJoin />
      {modalState && banner.length > 0 && (
        <Banner widthScreen={widthScreen} onClose={closeBanner} contentPopupBanner={banner} />
      )}

      {/* </Container> */}
    </div>
  )
}

export default Home