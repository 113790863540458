import { GetDataProgramBanner } from "@graphql/banner/getDataProgramBanner"
import fetcher from "lib/fetcher"
import ImageComponent from "lib/components/Image"
import router from "next/router"
import useSWR from "swr"
import { memo, useEffect } from "react"
import { gtmEcommerce } from "lib/gtm"
import { capitalize } from "lib/helpers"
import { isMobile } from "react-device-detect"
import ModalImage from "lib/components/Modal/image"

type Props = {
  onClose: () => void
  contentPopupBanner: {
    id?: string
    image_mobile_url: string
    link_url: string
    image_url: string
  }[]
  widthScreen?: number
}

const goToLinkUrl = (url: string) => {
  const date = new Date().toLocaleDateString()
  localStorage.setItem("modal_status", date)
  router.push(url)
}

function BannerModal({
  onClose,
  contentPopupBanner,
  widthScreen,
}: Props) {
  const getLastSegmentUrl = contentPopupBanner?.[0]?.link_url.split("/").pop()
  const { data: detailBanner } = useSWR<GetDataProgramBanner>(
    `/api/banner/get_banner_detail?code=${getLastSegmentUrl}`,
    fetcher,
  )

  useEffect(() => {
    if (detailBanner?.data.program_data.length > 0) {
      gtmEcommerce({ ecommerce: null })
      gtmEcommerce({
        event: "view_promotion",
        ecommerce: {
          items: [
            {
              item_id: contentPopupBanner[0].id,
              item_name: detailBanner?.data.program_data[0].program_name,
              currency: "IDR",
              creative_name: detailBanner?.data.program_data[0].program_name,
              creative_slot: 1,
              discount: Math.round(
                detailBanner?.data.program_data[0].price *
                  (detailBanner?.data.program_data[0].discount / 100),
              ),
              index: 1,
              item_brand: detailBanner?.data.program_data[0].tp_name,
              item_category: capitalize(detailBanner?.data.program_data[0].category_slug, "-"),
              item_variant: capitalize(detailBanner?.data.program_data[0].type),
              price: detailBanner?.data.program_data[0].price,
              promotion_id: detailBanner?.data.program_data[0].data_campaign?.id || null,
              promotion_name: detailBanner?.data.program_data[0].campaign
                ? capitalize(detailBanner?.data.program_data[0].campaign, "_")
                : null,
              quantity: 1,
            },
          ],
        },
      })
    }
  }, [detailBanner])

  if (isMobile) {
    return (
      <ModalImage
        onClose={onClose}
        closeIcon
        closeIconColor="white"
        size="banner"
        customClass="aspect-[0.9/1] hover:cursor-pointer bg-opacity-0"
        fullWidth>
        <div className="relative aspect-[0.9/1] overflow-hidden -m-4">
          <ImageComponent
            alt="banner"
            src={contentPopupBanner?.[0][widthScreen < 768 ? "image_mobile_url" : "image_url"]}
            objectFit={widthScreen < 768 ? "cover" : "fill"}
            onClick={async () => {
              if (detailBanner?.data.program_data.length > 0) {
                await gtmEcommerce({ ecommerce: null })
                await gtmEcommerce({
                  event: "select_promotion",
                  ecommerce: {
                    items: [
                      {
                        item_id: contentPopupBanner[0].id,
                        item_name: detailBanner?.data.program_data[0].program_name,
                        currency: "IDR",
                        creative_name: detailBanner?.data.program_data[0].program_name,
                        creative_slot: 1,
                        discount: Math.round(
                          detailBanner?.data.program_data[0].price *
                            (detailBanner?.data.program_data[0].discount / 100),
                        ),
                        index: 1,
                        item_brand: detailBanner?.data.program_data[0].tp_name,
                        item_category: capitalize(
                          detailBanner?.data.program_data[0].category_slug,
                          "-",
                        ),
                        item_variant: capitalize(detailBanner?.data.program_data[0].type),
                        price: detailBanner?.data.program_data[0].price,
                        promotion_id: detailBanner?.data.program_data[0].data_campaign?.id || null,
                        promotion_name: capitalize(
                          detailBanner?.data.program_data[0].campaign,
                          "_",
                        ),
                        quantity: 1,
                      },
                    ],
                  },
                })
                localStorage.setItem("selectedIndexItem", "1")
                localStorage.setItem("sectionName", detailBanner?.data.program_data[0].program_name)
              }
              goToLinkUrl(contentPopupBanner?.[0]?.link_url)
            }}
          />
        </div>
      </ModalImage>
    )
  }

  return (
    <ModalImage
      onClose={onClose}
      closeIcon
      closeIconColor="white"
      size="banner"
      customClass="h-[500px] hover:cursor-pointer bg-opacity-0"
      padding="none"
      fullWidth>
      <div className="relative h-[500px] overflow-hidden -m-4">
        <ImageComponent
          alt="banner"
          src={contentPopupBanner?.[0].image_url}
          onClick={async () => {
            if (detailBanner?.data.program_data.length > 0) {
              await gtmEcommerce({ ecommerce: null })
              await gtmEcommerce({
                event: "select_promotion",
                ecommerce: {
                  items: [
                    {
                      item_id: contentPopupBanner[0].id,
                      item_name: detailBanner?.data.program_data[0].program_name,
                      currency: "IDR",
                      creative_name: detailBanner?.data.program_data[0].program_name,
                      creative_slot: 1,
                      discount: Math.round(
                        detailBanner?.data.program_data[0].price *
                          (detailBanner?.data.program_data[0].discount / 100),
                      ),
                      index: 1,
                      item_brand: detailBanner?.data.program_data[0].tp_name,
                      item_category: capitalize(
                        detailBanner?.data.program_data[0].category_slug,
                        "-",
                      ),
                      item_variant: capitalize(detailBanner?.data.program_data[0].type),
                      price: detailBanner?.data.program_data[0].price,
                      promotion_id: detailBanner?.data.program_data[0].data_campaign?.id || null,
                      promotion_name: capitalize(detailBanner?.data.program_data[0].campaign, "_"),
                      quantity: 1,
                    },
                  ],
                },
              })
              localStorage.setItem("selectedIndexItem", "1")
              localStorage.setItem("sectionName", detailBanner?.data.program_data[0].program_name)
            }
            goToLinkUrl(contentPopupBanner?.[0]?.link_url)
          }}
        />
      </div>
    </ModalImage>
  )
}

export default memo(BannerModal)
